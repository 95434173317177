<template>
  <div class="whole">
    <Nav></Nav>
    <div class="all">
      <div class="title">视觉江苏网视觉版权作品</div>
      <div class="xieyi">委托代理协议</div>
      <div class="one">
        <div class="one-title">一、重要须知</div>
        <div class="one-content">
          <p>
            视觉江苏网(www.vijs.
            Net，下同)是中共江苏省委机关报新华日报社主办、新华日报社全媒体视觉中心承办的视觉版权产品发布及销售平台，视觉江苏网除负责新华报业传媒集团视觉版权产品的采集、加工、存储外，同时向集团内部以及省内外媒体(包括但不限于报纸、杂志、出版社、网站、微媒、APP等)提供图片、音视频和视觉创意设计素材产品。
          </p>
          <p>
            本协议是视觉版权作品作者与视觉江苏网之间就版权作品作者自愿将作品在全球范围内委托视觉江苏网代理对客户的销售、许可使用而达成的服务条款。
          </p>
          <p>本协议所称视觉版权作品包括但不限于图片、视频、创意素材。</p>
          <P
            >本协议所称视觉版权作品作者包括但不限于上传作品的摄影记者、摄影师、摄影家、摄影爱好者、视频师和创意素材设计师。</P
          >
          <P
            >用户完成注册程序并点击“同意”"按钮，即表示用户认可本协议所有的服务条款并与视觉江苏网达成协议!</P
          >
          <P
            >视觉江苏网如对本协议修订和补充，会在视觉版权作品作者进入下一步使用前的页面提示修改内容。如果视觉版权作品作者同意改动，则再一次激活“同意”按钮。如果视觉版权作品作者不接受，可第一时间解除本协议。解除协议前所上传作品仍按照本协议执行。</P
          >
        </div>
      </div>
      <div class="two">
        <div class="two-title">二、保证义务</div>
        <div class="two-content">
          <p>1、视觉江苏网的保证义务：</p>
          <p>
            视觉江苏网版权销售方为江苏新华日报传媒有限公司，有签订本协议并履行协议义务的资格与权利，代理活动不违反法律、法规，不侵犯他人的合法权益，不违反社会公德及公序良俗；
          </p>
          <p>
            视觉江苏网版权维权机构为新华报业传媒集团版权中心和新华报业传媒集团公司律师部，可以视觉江苏网的名义对外采取包括但不限于发函、诉讼等措施制止侵权行为；
          </p>
          <p>
            协议期间，视觉江苏网将尽力推广视觉版权作品作者的授权视觉版权作品，使视觉版权作品得到使用同时进行合理定价及收费，并按协议约定支付视觉版权作品作者的作品权利许可所得。
          </p>
          <p>2、视觉版权作品作者的保证义务</p>
          <p>
            保证在注册申请时提供的个人资料准确、真实、合法，如有变动及时更新，若由于视觉版权作品作者提供的个人资料不准确、不真实、不合法造成的损失完全由视觉版权作品作者个人承担；
          </p>
          <p>
            保证授权的视觉版权作品不违背中华人民共和国的相关法律、法规，不涉及任何侵权事宜，不对任何民事主体造成诽谤、侵害隐私权，不触犯任何第三方的版权、商标权、肖像权;
          </p>
          <p>
            保证对自己所申请的帐号和密码安全负完全的责任，若发现任何非法使用自己帐户或其他安全问题的，应立即通知视觉江苏网；
          </p>
          <p>
            保证授权的视觉版权作品及相关文字信息为其专有著作权，如果授权视觉版权作品为与他人共享著作权的，必须明确提示，并保证共享著作权人不再向甲方提出任何权利主张;乙方因著作权不合法引起的所有法律责任与甲方无关。
          </p>
          <p>
            保证在视觉版权作品著作权出现争议或发生侵权事件时，根据视觉江苏网的要求，及时、准确的提供任何文件、资料、信息以及视觉江苏网要求的其他协助，以帮助处理有关争议或侵权事件；视觉江苏网因使用视觉版权作品而遭遇侵权赔偿，可依据此协议向视觉版权作品作者予以全额追偿（包括但不限于因侵权所产生的赔偿、律师费用、公证费用及相关的一切合理开支）。
          </p>
        </div>
      </div>
      <div class="three">
        <div class="three-title">三、许可范围</div>
        <div class="three-content">
          <p>视觉版权作品作者对其所上传的视觉版权作品：</p>
          <p>
            1、许可视觉江苏网进行复制、翻译、修改、改编、汇编，且通过互联网和移动通讯网络在全球范围内的信息网络传播权。此外，委托视觉江苏网作为其作品在全球范围内的代理商，视觉江苏网有权将视觉版权作品作者委托代理之作品许可第三方使用，包括但不仅限于视觉江苏网有权将作品使用于国内外之出版物、广告宣传品及其他用途上；或由视觉江苏网销售或许可给任何第三方。
          </p>
          <p>
            2、许可授予视觉江苏网筛选和编辑权，视觉江苏网有权对作品进行筛选、组合，并选择其认为适合的视觉版权作品进行编辑，并将其录入图片库、视频库或创意素材库;视觉江苏网有权对作品的尺寸、构图、文字或结构进行调整，并在作品上增加视觉江苏网的水印（含隐形水印）或其他不破坏作品主题的内容;
          </p>
          <p>
            3、许可授予视觉江苏网作品定价权，视觉江苏网有权对作品按照市场需求进行定价并进行调整；视觉江苏网有权代理作者接受国内外下载用户支付稿酬，并按本协议规定之比例与视觉版权作品作者分享；
          </p>
          <p>
            4、许可新华报业传媒集团所属子媒体使用权，新华报业传媒集团所属的报刊、网络、微博、微信、移动客户端及公众号有权使用视觉版权作品作者所上传视觉版权作品，并按照通常标准支付稿费。
          </p>
          <p>
            5、许可新华报业传媒集团所属各类子媒体在自己主办或与其他单位合办的展览、展示及出版等活动中使用；
          </p>
          <p>
            6、许可新华报业传媒集团所属各类子媒体在自己主办或与其他单位合并的作品征集比赛中自动列为参赛作品；
          </p>
          <p>
            7、许可授权视觉江苏网在政治宣传、社会公益活动时无偿使用的权利;同意在网页展示、客户使用时不一定有署名。
          </p>
          <p>
            8、独家代理作品被侵权时，许可视觉江苏网及其版权维护机构以自己的名义对外采取包括但不限于发函、诉讼等措施制止侵权行为，在此情况下，视觉版权作品作者应视觉江苏网的要求，应提供一切必要的帮助，包括但不限于提供著作权属证明、已对外授权名单等。
          </p>
        </div>
        <div class="four">
          <div class="four-title">四、收益权利</div>
          <div class="four-content">
            <p>视觉版权作品作者授权的作品：</p>
            <p>
              1、被新华报业传媒集团所属的网站、报纸、杂志、广播电视、电子出版物以及其他现存和未来发展产生的的媒体使用，由相关媒体按各自标准直接向视觉版权作品作者支付稿费，也可由相关媒体委托视觉江苏网代发稿费。视觉江苏网不收取中间费用。
            </p>
            <p>
              2、被商业用户购买或者被非新华报业传媒集团所属网站、报纸、杂志、广播电视、电子出版物以及其他现存和未来发展产生的的媒体采用，视觉江苏网将向视觉版权作品作者支付不低于实际收到授权使用作品净收入的30%，视觉江苏网也可根据作品质量和视觉版权作品作者水平适当提高标准；视觉版权作品作者所得款项应按有关规定由视觉江苏网代缴个人收入所得税；
            </p>
            <p>
              3、视觉江苏网有权通过评选十佳、优秀摄影（视频、素材）师、发放特殊稿费等方式对视觉版权作品作者进行鼓励。
            </p>
            <p>
              4、被新华报业传媒集团所属各类子媒体（含视觉江苏网）主办或与其他单位合办的展览、展示及出版等活动中使用，按照展览、展示或出版物规定标准支付稿费。
            </p>
            <p>
              5、在新华报业传媒集团所属各类子媒体（含视觉江苏网）主办或与其他单位合并的作品征集比赛中自动列为参赛作品并获奖的，根据征稿启事标准获得全额奖金；
            </p>
            <p>
              6、被视觉江苏网进行再创作的，新生作品的著作权属双方共有，权利许可之所有30%归视觉版权作品作者所有；
            </p>
            <p>
              7、被客户使用作品所应收到或产生的收入被取消或视觉江苏网必须退款，此协议明确规定视觉江苏网有权对多付款项或未收款项在随后应付该摄影师的费用中扣除；
            </p>
            <p>
              8、独家代理之作品，视觉江苏网对第三方侵害著作权行为进行追究及法律诉讼，视觉江苏网负责预支法律开支（诉讼费、律师费等），追缴所得部分扣除法律开支后50%归视觉版权作品作者所有。
            </p>
          </div>
        </div>
        <div class="five">
          <div class="five-title">五、收益支付</div>
          <div class="five-content">
            <p>
              1、被新华报业传媒集团所属的网站、报纸、杂志、广播电视、电子出版物以及其他现存和未来发展产生的的媒体使用的，由各媒体根据自身财务制度发放稿费。
            </p>
            <p>
              2、被商业用户购买或者被非新华报业传媒集团所属网站、报纸、杂志、广播电视、电子出版物以及其他现存和未来发展产生的的媒体采用的，在费用到达江苏新华日报传媒有限公司财务账号3个月内支付；
            </p>
            <p>
              3、被视觉江苏网主办或与其他单位合办的展览、展示及出版等活动中使用，或在视觉江苏网主办或与其他单位合并的作品征集比赛中自动列为参赛作品并获奖的，在相关经费到江苏新华日报传媒有限公司财务账号3个月内支付；
            </p>
            <p>
              4、收益均通过视觉版权作品作者注册时所登记个人银行账号支付，如该账号发生变动，视觉版权作品作者应该及时登录个人账号修改信息并通知视觉江苏网。未及时通知导致稿费无法发放的，由视觉版权作品作者本人承担。
            </p>
          </div>
        </div>
        <div class="six">
          <div class="six-title">六、保密义务</div>
          <div class="six-content">
            <p>
              视觉版权作品作者须保管好自己的用户名和密码，如因视觉版权作品作者自己的原因造成用户名和密码泄漏，对因此造成的相关损失应由视觉版权作品作者自己负责。
            </p>
            <p>
              视觉版权作品作者如果发现自己的用户名和密码泄漏或者发现任何非法使用其用户名和密码的情况，有义务及时通知视觉江苏网，视觉江苏网在接到通知后应当及时采取补救措施。
            </p>
          </div>
        </div>
        <div class="seven">
          <div class="seven-title">七、网络储存期限</div>
          <div class="seven-content">
            <p>
              视觉版权作品作者的作品数据将长期储存于视觉江苏网的网络服务器，视觉版权作品作者可以通过网络浏览、下载自己的作品。
            </p>
            <p>
              视觉版权作品作者作品数据的网络储存期限由视觉江苏网决定。作品经视觉江苏网判断已经不具备使用价值的，视觉江苏网将在网络服务器上进行删除，无需告知作者。
            </p>
          </div>
        </div>
        <div class="eight">
          <div class="eight-title">八、其他事项</div>
          <div class="eight-content">
            <p>
              1、本协议自签署之日起生效，有效期5年。除非双方提前终止本协议，期满之后，若双方没有异议，本协议将自然延续5年;如果一方要终止协议，须以书面形式正式通知另一方。在另一方收到正式书面通知之前，视觉版权作品代理协议仍然有效。在本协议期限届满后，用户在本协议有效期间提供的图片视觉江苏网仍有权存储、销售、推广，并按本协议约定的条件向用户支付稿酬。
            </p>
            <p>
              2、视觉版权作品作者如无书面文件表示异议，本协议签订之前视觉版权作品作者上传到视觉江苏网的作品，自动适用本协议各项条款。
            </p>
            <p>
              3、本协议的双方当事人如因执行本协议发生争执，均可协商解决，如协商无法解决，任何一方有权向南京市有管辖权的人民法院提起诉讼。
            </p>
            <p>
              4、本协议的一条或几条条款被认定为无效、非法或不能执行，并不影响其他条款的效力和合法性，并且双方应当以最接近于该条款意图的新条款替代被认定为无效、非法和不能执行的条款。
            </p>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.all {
  width: 1200px;
  margin: 0 auto;
  height: auto;
  .title {
    font-size: 36px;
    line-height: 95px;
  }
  .xieyi {
    font-size: 32px;
  }
  .one,
  .two,
  .three,
  .four,
  .five,
  .six,
  .seven,
  .eight {
    text-align: left;
    .one-title,
    .two-title,
    .three-title,
    .four-title,
    .five-title,
    .six-title,
    .seven-title,
    .eight-title {
      font-size: 20px;
      font-weight: bold;
      text-indent: 30px;
    }
    .one-content,
    .two-content,
    .three-content,
    .four-content,
    .five-content,
    .six-content,
    .seven-content,
    .eight-content {
      font-size: 18px;
      line-height: 30px;
      p {
        text-indent: 30px;
      }
    }
  }
}
</style>
